header button {
  margin: 0 .5rem;
}

header li button {
  margin: 0;
}

header img {
  margin-right: 20px;
}

header h5 span {
  text-transform: uppercase;
}
